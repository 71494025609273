@import "./Colors.scss";

#root {
  background-color: #eeeeee;
}

.header {
  @apply sticky top-0 w-full items-center py-1.5 px-4 grid grid-cols-3 z-50;
  background: linear-gradient(0.25turn, #006632, #58A813);
}

#logo-header {
  width: 100px;
}

.main-title {
  margin: auto;
  text-align: center;
}

.main-title-user {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.custom-dropdown > div > ul {
  @apply py-0;
  & > li {
    @apply rounded-md
  }
}

.custom-table > div > div {
  @apply rounded-md shadow-none mt-0;
}
.custom-table > div > div {
  @apply rounded-md shadow-none mt-4;
}

.custom-table > * > th {
  @apply bg-white;
}

.MuiFormControl-root.makeStyles-formControl-1,
.MuiFormControl-root.makeStyles-formControl-3, 
.MuiFormControl-root.makeStyles-formControl-4 {
  max-width: initial;
  width: 100%;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-sm-6.MuiGrid-grid-md-3 {
  margin-top: auto;
}
.MuiInputBase-root.MuiInput-root.MuiInput-underline,
.MuiInputBase-root.MuiInput-root.MuiInput-underline
{
  font-size: 14px;
  padding-top: 0.5rem;
}

.MuiPaper-root.makeStyles-root-11.MuiPaper-elevation1.MuiPaper-rounded {
  box-shadow: none;
}